
/**
 * Component: Button
 * --------------------------------------------------
 */

.c-button,
.btn-ripple{
    -webkit-appearance: none;
    position: relative;
    display: inline-block;

}

.c-ripple {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: transparent;
}

.c-ripple__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, .25);
.c-ripple.is-active & {
    animation: a-ripple .4s ease-in;
}
}

/**
 * Animation: Ripple
 * --------------------------------------------------
 */

@keyframes a-ripple {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    100% {
        width: 200%;
        padding-bottom: 200%;
        opacity: 0;
    }
}