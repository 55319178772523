/* You can add global styles to this file, and also import other style files */
@import "assets/scss/boostrap-custom";
@import "assets/scss/btn-ripple";
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";

$w: #fff;

* {
  box-sizing: border-box;
  font-family: 'Fira Sans', sans-serif;
}

body {
  // color: $primary;
}

$adminMenuColor: #E7E7E7;
$adminMenuBgColor: #2A3F54;
$adminMenuBgHoverColor: #334c65;

$adminMenuActiveColor: #E7E7E7;
$adminMenuActiveBgColor: #3579bb;

$adminMenuChildBgColor: #1f2f40;
$adminMenuChildPrefixColor: #bfbfbf;

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.language-selector {
  color: #ccc;
}

.language-selector .c-token {
  background: transparent !important;
}

.language-selector .c-label {
  color: #000 !important;
}

.language-selector .c-token .c-remove {
  display: none !important;
}

.language-selector .pure-checkbox > label:before,
.language-selector .pure-checkbox > label:after {
  display: none !important;
}

.language-selector .pure-checkbox {
  padding: 6px !important;
  padding-top: 0px !important;
  margin: 0px !important;
  text-align: center !important;
}

.language-selector .badge-item-img,
.language-selector .pure-checkbox .list-item-img {
  width: 30px !important;
}

.language-selector .pure-checkbox .list-item-name {
  display: block !important;
  text-align: center !important;
  color: #000 !important;
  padding: 0 !important;
  margin: 0 !important;

}

.language-selector .c-angle-up,
.language-selector .c-angle-down {
  display: none !important;
}

.language-selector .c-btn {
  padding: 0px!important;
  text-align: center!important;
  margin: 0!important;
  padding-bottom: 1px!important;
  /* background: transparent; */
  border: 0px!important;
  outline: 0!important;
  background: transparent!important;

}

.language-selector .dropdown-list .arrow-up {
  margin-left: 21px!important;
  margin-top: 2px;
  border-bottom: 13px solid #fff;
}
.language-selector .c-list {
  float: none!important;
  margin: 0 auto!important;
  width: 30px!important;
  background: transparent!important;
  border: 0px!important;
  outline: 0!important;
}
.language-selector .c-token {
  padding: 0px!important;
  margin-top: -1px!important;
  border: 7px solid whitesmoke;
}

.language-selector .selected-list .c-btn {
  width: 60px!important;
  height: 42px!important;
  padding-top: 3px!important;
  margin-top: -11px!important;
  margin-left: -15px!important;
  user-select: none!important;
}
.language-selector .dropdown-list {
  width: 65px!important;
  margin-left: -17px!important;
}

/*
.language-selector .pure-checkbox label::before {
  border-color: #38d574 !important;
}
.language-selector .pure-checkbox input[type="checkbox"]:checked + label[_ngcontent-c1]:before {
  background: #38d574 !important;
}
*/

/* AG-grid */

.ag-grid-container {

  .ag-body-viewport {
    min-height: 200px;
  }

  .ag-grid-container {
    text-align: left;
  }

  .ag-root-wrapper {
    overflow: visible;
  }

  .ag-menu {
    max-height: none;
  }

}
/* Custom design */

.red-highlight {
  background-color: #E63D12;
  padding: 5px 12px;
}

.blue-bg {
  background-color: #03B09B;
}
.blue-btn {
  background-color: #03B09B;
  border-color: #03B09B ;
  color: #FFFFFF;
}
.blue-btn:hover {
  border-color: #03B09B;
  background-color: #039986;
}
.blue-btn:active,
.blue-btn:focus {
  border-color: #03B09B!important;
  background-color: #039986!important;
  box-shadow: 0 0 0 0.2rem rgba(3, 153, 134, 0.25)!important;
}
.blue-text {
  color: #008070;
}
.blue-circle {
  color: #03B09B;
}

.red-bg {
  background-color: #E63D12;
}
.red-btn {
  background-color: #E63D12;
  border-color: #E63D12 ;
  color: #FFFFFF;
}
.btn.red-btn:hover {
  border-color: #E63D12;
  background-color: #cd3812;
}
.btn.red-btn:active,
.btn.red-btn:focus {
  border-color: #E63D12!important;
  background-color: #cd3812!important;
  box-shadow: 0 0 0 0.2rem rgba(205, 56, 18, 0.24)!important;
}

.red-text {
  color: #AF2805;
}
.red-circle {
  color: #E63D12;
}

.yellow-bg {
  background-color: #F9B21B;
}
.yellow-btn {
  background-color: #F9B21B;
  border-color: #F9B21B ;
  color: #FFFFFF;
}
.yellow-btn:hover {
  border-color: #F9B21B;
  background-color: #db9c17;
}
.yellow-btn:active,
.yellow-btn:focus {
  border-color: #F9B21B!important;
  background-color: #db9c17!important;
  box-shadow: 0 0 0 0.2rem rgba(219, 156, 23, 0.25)!important;
}
.yellow-text {
  color: #CA8C2B;
}
.yellow-circle {
  color: #F9B21B;
}
